function Map() {
  return (
    <div class="row gy-4 mt-1">
      <div class="col-lg-12" data-aos="fade-up" data-aos-delay="300">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.330600428275!2d144.74552947628618!3d-37.75884567198935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad68ad33f53a1dd%3A0x397b74a42a9e913a!2s1042%20Western%20Hwy%2C%20Caroline%20Springs%20VIC%203023%2C%20Australia!5e0!3m2!1sen!2s!4v1722763706350!5m2!1sen!2s" frameborder="0" style={{border: 0, width: '100%', height: '400px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
}

export default Map;