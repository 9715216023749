export const services = [
  {
    id: 1,
    title: 'Home Loans',
    description: "We understand that buying your first home is one of the biggest financial decisions you'll ever make. It's valuable to have someone by your side to guide...",
    icon: 'fa-solid fa-house',
    kind: 'Individual'
  },
  {
    id: 2,
    title: 'Personal Loans',
    description: 'Whatever stage of life we might be in, sometimes we need funds in a quick turnaround time.They could be for fixing a broken heater, buying household goods...',
    icon: 'fa-solid fa-person',
    kind: 'Individual'
  },
  {
    id: 3,
    title: 'Car Loan',
    description: "It's wise to obtain pre-approval before visiting a dealership or committing to buying a vehicle. At VED, we have access to numerous lenders and can negotiate...",
    icon: 'fa-solid fa-car',
    kind: 'Individual'
  },
  {
    id: 4,
    title: 'Refinance / Debt Consolidation',
    description: 'Try again for your benefit. Conducting a health check on your current home loan and comparing it with market options can save you thousands...',
    icon: 'fa-solid fa-dollar-sign',
    kind: 'Individual'
  },
  {
    id: 5,
    title: 'Commercial Loans',
    description: "Whether you're planning to start a new business, buy a franchise, or expand an existing one, we can assist you...",
    icon: 'fa-solid fa-dumpster-fire',
    kind: 'Corporates'
  },
  {
    id: 6,
    title: 'Cash Flow Lending',
    description: 'Cash flow is a concern that often keeps many business owners up at night...',
    icon: 'fa-solid fa-microchip',
    kind: 'Corporates'
  },
  {
    id: 7,
    title: 'Asset / Equipment Finance',
    description: 'Equipment Finance allows you to secure funding for business equipment, machinery, and vehicles (such as trucks and tractors). You can borrow up to 100%...',
    icon: 'fa-solid fa-dollar-sign',
    kind: 'Corporates'
  },
  {
    id: 8,
    title: 'Line of credit / Business Overdraft',
    description: 'Lines of credit are flexible loan facilities that allow you to draw funds as needed without requiring additional approvals. A cash flow line of credit is commonly...',
    icon: 'fa-solid fa-business-time',
    kind: 'Corporates'
  },
  {
    id: 9,
    title: 'LO Doc Loan',
    description: 'Low Documentation (Low Doc) loans offer flexible financing solutions for self-employed individuals...',
    icon: 'fa-solid fa-paperclip',
    kind: 'Corporates'
  },
  {
    id: 10,
    title: 'Debt Removal',
    description: 'If you have bad credit, you know how hard life can become! From everyday purchases to investments in your future, your credit history can come back to haunt you...',
    icon: 'fa-solid fa-dollar-sign',
    kind: ''
  },
  {
    id: 11,
    title: 'SMSF Loans',
    description: 'As a property investor (including investing through super funds (SMSF)), we have experts who work with you to understand your immediate and longer term financing needs...',
    icon: 'fa-solid fa-chart-simple',
    kind: ''
  }
];